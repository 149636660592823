import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Grid from '@material-ui/core/Grid';

import {theme} from '../../../themes/default-theme';
import RespImg from '../RespImg';
import NextLink from '../../navigation/NextLink';
import Text from '../../typography'

const TitleContainer = styled.div`
  font-size: 28px;
  text-align: center;
  font-weight: 500;
  color: ${theme.palette.charcoal};
  max-width: 780px;
  margin: 0 auto;
  padding-bottom: 46px;
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
    margin: 0 16px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.gunmentalGrey};
    ${theme.breakpoints.down('sm')} {
      font-size: 14px;
    }
  }
`

const GridContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  a {
    display: block;
    text-decoration: none;
  }
`

const GridIndividual = styled(props => {
  return <Grid {...props} />
})`
  width: 33.33%;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  padding: 0 16px;
  &.expanded-grid {
    ${theme.breakpoints.up('lg')} {
      width: 25%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    ${theme.breakpoints.down('sm')} {
      width: 50%;
    }
  }
  ${theme.breakpoints.down('md')} {
    width: 50%;
    margin-bottom: 25px;
  }
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const CellTitle = styled.div`
  font-size: 20px;
  color: ${theme.palette.vividBlue};
  font-weight: 500;
  ${theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`
const CellCaption = styled.div`
  font-size: 16px;
  color: ${theme.palette.gunmentalGrey};
  ${theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`

const IconCell = styled.div`
  width: 140px;
  height: 140px;
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
  ${theme.breakpoints.down('md')} {
    width: 98px;
    height: 98px;
  }
  ${theme.breakpoints.down('sm')} {
    width: 80px;
    height: 80px;
  }
`

const GridItem = styled.div`

`

export const IconTextGrid = props => {
  const {data} = props;

  console.log('icon text grid', props);

  return (
    <div>
      {(data.heading || data.description) &&
        <TitleContainer>
          {data.heading &&
            <Text variant="h4" colour="charcoal" uppercase={true} align="center">{data.heading}</Text>
          }
          <Text variant="body1" colour="charcoal" align="center">{ReactHtmlParser(data.description)}</Text>
        </TitleContainer>
      }
      <GridContainer>
        <Grid container direction={'row'} align="center" justify="center">
          {data.grid && data.grid.map((cell, index) => (
            <GridIndividual className={data.grid.length > 7 ? "expanded-grid grid-individual" : "grid-individual"} key={index}>
              <NextLink href={cell.link || undefined}>
                <a>
                  <IconCell>
                    {cell.image &&
                      <RespImg {...cell.image} />
                    }
                  </IconCell>
                  {cell.title && <Text uppercase colour="charcoal" align="center" variant="subtitle1">{cell.title}</Text>}
                  {cell.description && <Text variant="body1" align="center">{cell.description}</Text>}
                </a>
              </NextLink>
            </GridIndividual>
          ))}
        </Grid>
      </GridContainer>
    </div>
  );
}

IconTextGrid.propTypes = {
  data: PropTypes.object
};

IconTextGrid.defaultProps = {
  data: {}
}

export default IconTextGrid;